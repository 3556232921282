<template>
  <v-app>
    <v-main>
      <v-container class="fill-height backgound" fluid>
        <v-row>
          <v-col class="align-center d-flex flex-column">
            <div class="logo-sistema">
              <v-img
                class="image-background"
                :src="require('@/assets/campanha-e-comissao.svg')"
              >
              </v-img>
            </div>

            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style scoped>
.backgound {
  background-image: linear-gradient(to bottom, #0d47a1 40%, #ffffff 35%);
}

.logo-sistema {
  position: relative;
}

@media (max-width: 575.98px) {
  .card-cadastro {
    width: 350px;
  }

  .actions {
    display: block !important;
  }

  .btn-actions {
    margin: 10px 10px !important;
    width: 95% !important;
  }

  .image-background {
    width: 350px;
  }
}
</style>
